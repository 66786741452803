<template>
   <div>
      <ValidationObserver v-slot="{ invalid }" tag="div" class="">
         <form class="flex flex-col justify-center" @submit.prevent="submit()">
            <div class="grid grid-cols-2 gap-5 bg-white rounded-md border p-10 ">
               <div class="text-xl text-indigo-900 col-span-2 pb-5 font-semibold">Security Question 1</div>
               <ValidationProvider tag="div" name="Question one" rules="required" v-slot="{ errors }">
                  <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Question</label>
                  <input type="text" v-model="form.question_one"
                     class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                     :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                     spellcheck="false"/>
                     <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
               </ValidationProvider>
               <ValidationProvider tag="div" name="Question one answer" rules="required" v-slot="{ errors }">
                  <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Answer</label>
                  <input type="text" v-model="form.answer_one"
                     class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                     :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                     spellcheck="false"/>
                     <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
               </ValidationProvider>
            </div>
            <hr class="border-t border-gray-200 border-dotted col-span-2 my-4">
            <div class="grid grid-cols-2 gap-5 bg-white rounded-md border p-10 ">
               <div class="text-xl pb-4 text-indigo-900 col-span-2 font-semibold">Security Question 2</div>
               <ValidationProvider tag="div" name="Question two" rules="required" v-slot="{ errors }">
                  <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Question</label>
                  <input type="text" v-model="form.question_two"
                     class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                     :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                     spellcheck="false"/>
                     <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
               </ValidationProvider>
               <ValidationProvider tag="div" name="Question two answer" rules="required" v-slot="{ errors }">
                  <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Answer</label>
                  <input type="text" v-model="form.answer_two"
                     class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                     :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                     spellcheck="false"/>
                     <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
               </ValidationProvider>
            </div>
            <hr class="border-t border-gray-200 border-dotted col-span-2 my-4">
            <div class="grid grid-cols-2 gap-5 bg-white rounded-md border p-10 ">
               <div class="text-xl pb-4 text-indigo-900 col-span-2 font-semibold">Question 3</div>
               <ValidationProvider tag="div" name="Question three" rules="required" v-slot="{ errors }">
                  <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Question</label>
                  <input type="text" v-model="form.question_three"
                     class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                     :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                     spellcheck="false"/>
                     <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
               </ValidationProvider>
               <ValidationProvider tag="div" name="Question three answer" rules="required" v-slot="{ errors }">
                  <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Answer</label>
                  <input type="text" v-model="form.answer_three"
                     class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                     :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                     spellcheck="false"/>
                     <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
               </ValidationProvider>
            </div>
            <div class="mt-8 flex justify-end">
               <button type="submit" class="leading-4 inline-flex items-center cursor-pointer transition 
                  ease-in-out duration-300 font-medium text-center justify-center border-0 border-transparent rounded-md 
                  focus-visible:outline-none focus:outline-none focus:bg-opacity-80 bg-heading text-white px-5 py-4 md:px-6 bg-indigo-900 
                  hover:bg-indigo-800 mt-1.5" :disabled="invalid">
                  <svg v-show="isLoading" class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
                     <!-- ... -->
                  </svg> Save
               </button>
            </div>
         </form>
      </ValidationObserver>
   </div>
</template>

<script>
export default {
   data() {
      return {
         form: {},
         isLoading: false
      }
   },

   methods: {
      async submit() {

      }
   }
}
</script>