var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"flex flex-col justify-center",on:{"submit":function($event){$event.preventDefault();return _vm.submit()}}},[_c('div',{staticClass:"grid grid-cols-2 gap-5 bg-white rounded-md border p-10 "},[_c('div',{staticClass:"text-xl text-indigo-900 col-span-2 pb-5 font-semibold"},[_vm._v("Security Question 1")]),_c('ValidationProvider',{attrs:{"tag":"div","name":"Question one","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-600 font-semibold leading-none mb-3 cursor-pointer"},[_vm._v("Question")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.question_one),expression:"form.question_one"}],staticClass:"py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12",class:errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 ',attrs:{"type":"text","spellcheck":"false"},domProps:{"value":(_vm.form.question_one)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "question_one", $event.target.value)}}}),_c('span',{staticClass:"text-sm text-red-600 font-semibold"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"tag":"div","name":"Question one answer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-600 font-semibold leading-none mb-3 cursor-pointer"},[_vm._v("Answer")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.answer_one),expression:"form.answer_one"}],staticClass:"py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12",class:errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 ',attrs:{"type":"text","spellcheck":"false"},domProps:{"value":(_vm.form.answer_one)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "answer_one", $event.target.value)}}}),_c('span',{staticClass:"text-sm text-red-600 font-semibold"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('hr',{staticClass:"border-t border-gray-200 border-dotted col-span-2 my-4"}),_c('div',{staticClass:"grid grid-cols-2 gap-5 bg-white rounded-md border p-10 "},[_c('div',{staticClass:"text-xl pb-4 text-indigo-900 col-span-2 font-semibold"},[_vm._v("Security Question 2")]),_c('ValidationProvider',{attrs:{"tag":"div","name":"Question two","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-600 font-semibold leading-none mb-3 cursor-pointer"},[_vm._v("Question")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.question_two),expression:"form.question_two"}],staticClass:"py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12",class:errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 ',attrs:{"type":"text","spellcheck":"false"},domProps:{"value":(_vm.form.question_two)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "question_two", $event.target.value)}}}),_c('span',{staticClass:"text-sm text-red-600 font-semibold"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"tag":"div","name":"Question two answer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-600 font-semibold leading-none mb-3 cursor-pointer"},[_vm._v("Answer")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.answer_two),expression:"form.answer_two"}],staticClass:"py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12",class:errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 ',attrs:{"type":"text","spellcheck":"false"},domProps:{"value":(_vm.form.answer_two)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "answer_two", $event.target.value)}}}),_c('span',{staticClass:"text-sm text-red-600 font-semibold"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('hr',{staticClass:"border-t border-gray-200 border-dotted col-span-2 my-4"}),_c('div',{staticClass:"grid grid-cols-2 gap-5 bg-white rounded-md border p-10 "},[_c('div',{staticClass:"text-xl pb-4 text-indigo-900 col-span-2 font-semibold"},[_vm._v("Question 3")]),_c('ValidationProvider',{attrs:{"tag":"div","name":"Question three","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-600 font-semibold leading-none mb-3 cursor-pointer"},[_vm._v("Question")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.question_three),expression:"form.question_three"}],staticClass:"py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12",class:errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 ',attrs:{"type":"text","spellcheck":"false"},domProps:{"value":(_vm.form.question_three)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "question_three", $event.target.value)}}}),_c('span',{staticClass:"text-sm text-red-600 font-semibold"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"tag":"div","name":"Question three answer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-600 font-semibold leading-none mb-3 cursor-pointer"},[_vm._v("Answer")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.answer_three),expression:"form.answer_three"}],staticClass:"py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12",class:errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 ',attrs:{"type":"text","spellcheck":"false"},domProps:{"value":(_vm.form.answer_three)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "answer_three", $event.target.value)}}}),_c('span',{staticClass:"text-sm text-red-600 font-semibold"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mt-8 flex justify-end"},[_c('button',{staticClass:"leading-4 inline-flex items-center cursor-pointer transition \n               ease-in-out duration-300 font-medium text-center justify-center border-0 border-transparent rounded-md \n               focus-visible:outline-none focus:outline-none focus:bg-opacity-80 bg-heading text-white px-5 py-4 md:px-6 bg-indigo-900 \n               hover:bg-indigo-800 mt-1.5",attrs:{"type":"submit","disabled":invalid}},[_c('svg',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"animate-spin h-5 w-5 mr-3 ...",attrs:{"viewBox":"0 0 24 24"}}),_vm._v(" Save ")])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }